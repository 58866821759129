import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => (
	<Layout>
		<SEO title="Polityka prywatności" />
	</Layout>
)

export default PrivacyPolicy
